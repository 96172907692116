import { useLocation } from "@solidjs/router";
import { PopulateRouteHrefProps } from "../models";

export function mergeRouteHref(...args: PopulateRouteHrefProps[]) {
  let to: Exclude<PopulateRouteHrefProps, string> = {};
  for (const arg of args) {
    if (typeof arg === "string") {
      to.path = arg;
    } else {
      to = { ...to, ...arg };
    }
  }
  return to;
}
